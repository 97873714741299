body {
  background-image: url("../images/background-image.jpg");
  background-repeat: no-repeat;
  background-color:  #eaeae1;
  background-size:  100%;
}
.panel {
  border: 0;
  margin-top: 160px;
  margin-bottom: 0;
  .panel-body{
    margin: 0;
  }
  @media (min-width:768px) and (max-width:1024px) {
    margin-top: 120px;
  }
}

.deco_line{
  color: #d2232a;
  font-family: "kepler-std-display";
  font-weight: bold;
}

.hermes {
  display: none;
  img{
    height: 50px;
  }
}

.language_selector {
  right: 0;
  position: absolute;
  z-index: 9999;

  @media (max-width: 48em) {
    float: left;
    left: 5px;

  }

  span {
    font-weight: bold;
    font-size: 12px;
    color: black;

    @media (max-width: 48em) {
        font-size: 12px;
    }
  }

  a.active {
    span {
      padding-bottom: 5px;
      // border-bottom: 4px solid #d2232a;
       color: #d2232a;
    }
  }
}


.footer {
    .top{
        text-align: center;
        background-color: #222222;
        p{
          color: #eaeae1;
          margin: 10 30px 0 30px;
        }
        .mobile-none {
            display: block;
            @media (max-width: 48em) {
              display: none;
            }
        }
          .mobile-block{
            display: none;
            @media (max-width: 48em) {
              display: block;
            }
        }
        .footer_paragraph{
          padding: 20px;
        }
        .footer__brand-header{
            padding: 20px 0;
            img{
                max-width: 30%;
                max-height: 100%;
                @media (max-width: 48em) {
                  max-width: 100%;
                  padding: 0 10px;
                }
            }
        }
        .footer__brand-bottom{
            display: flex;
            justify-content: center;
            @media (max-width: 48em) {
              display: block;
            }
            .footer__brand-item{
              height: 100px;
              margin: 15px 15px 0 15px;
              @media (max-width: 48em) {
                display: block;
              }
              img {
                // max-width: 60%;
                max-width: 70%;
                max-height: 100%;

              }
              @media (max-width: 48em) {
                img {
                  max-width: 100%;
                  width: auto;
                  max-height: 100%;
                  padding: 20px;
                }
              }
            }
        }
    }

    .bottom{
        text-align: center;
        padding: 10px;
        a{
          color: #d2232a;
        }
    }
}