.content__header-other{
	background-size: content;
	height: 300px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	@media (max-width: 48em) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 120px;
	}

	@media (min-width:768px) and (max-width:1024px) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 120px;
	}

	.content__header-title{
		position: absolute;
		padding: 0 50px;
		display: flex;
		justify-content: center;
		align-self: center;
		@media (max-width: 48em) {
			padding: 0;
			padding: 0;
			top: 0;
			position: relative;
		}
		@media (min-width:768px) and (max-width:1024px) {
			padding: 0;
			padding: 0;
			top: 0;
			position: relative;
		}

		h4{
			font-size: 60px;
			color: white;
			text-align: center;
			font-weight: bold;
			font-family: "kepler-std-display";
			@media (max-width: 48em) {
				font-size: 30px;
			}
			@media (min-width:768px) and (max-width:1024px) {
				font-size: 30px;
			}

		}
		h4.chinese-header{
			font-family: "source-han-serif-sc";
		}
	}
}

.content__header-banner{
	height: 300px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-repeat: none;
	background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	@media (max-width: 48em) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 120px;
	}
	@media (min-width:768px) and (max-width:1024px) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 120px;
	}

	h1{
		font-family: "kepler-std-display";
		color: white;
		text-decoration: none;
		font-size: 60px;
		@media (max-width: 48em) {
			font-size: 30px;
		}
		@media (min-width:768px) and (max-width:1024px) {
			font-size: 30px;
		}
	}
	h1.chinese-header{
		font-family: "source-han-serif-sc";
	}
}

.banner__link:hover{
	text-decoration: none;
}