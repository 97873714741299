.content__awards {
	.bg-img-winners-list{
		background-image: url("../images/awards/winnerslist-1920.jpg");
	}
	.bg-img-list-of-awards{
		background-image: url("../images/awards/list-of-awards-1920.jpg");
	}

	.content__title, .content__description {
		text-align: center;
	}

	.content__title {
		padding: 40px 140px 0px 140px;
		font-family: "kepler-std-display";
		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.content__description {
		padding: 20px 140px 40px 140px;
		a{
			color: #d2232a;
		}
		@media (max-width: 48em) {
			padding: 10px;
		}
		width: 100%;
		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}

		.content__div {
			ul {
				padding-left: 0;
			}
		}

		.content__nav {
			padding: 0px;
			.nav-tabs {
				ul {
				    list-style-type: none;
				    padding: 0;
				    overflow: hidden;
				    background-color: transparent;
				    display: inline-block;
				    @media (max-width: 48em) {
				        display: flex;
					    flex-wrap: wrap;
					    justify-content: center;
					}
				    .active {a{color: black;}}

				    li {
				    	float: left;
					}

					li a {
		
					    text-align: center;
					    padding: 14px 16px;
					    text-decoration: none;
					    color: #d2232a;
					    font-weight: bold;
					    @media (min-width:768px) and (max-width:1024px) {
					    	padding: 14px 12px;
					    }
					}

					li:after {
						content: " \2022";
					}
				}
			}

			.content__button{
				background-color: black; /* Green */
			    border: none;
			    color: white;
			    padding: 14px 15px;
			    text-align: center;
			    text-decoration: none;
			    display: inline-block;
			    font-size: 16px;
			    cursor: pointer;
			    margin: 20px 15px 20px 15px;
			}

			.nav__active {
				background-color: white; /* Green */
			    border: 1px solid black;
			    color: black;
			}
		}

		.tab-content {

			.content__div>ul {
				list-style-type: none;
			}
			.content__table {
				width: 100%;
				td {
					padding:5px 0; 
					margin:0;
				}
				td.title{
					text-align: left;
					@media (max-width: 48em) {
						width: 40%;
					}
				}
				td.winner{
					text-align: right;
				}

				tr {
					border-bottom: 1px solid #dcdcdc;
				}
			}
			.content__list-group {
				margin-bottom: 20px;
	    		padding-left: 0;
	    		text-align: left;

				.content__list-group-item {
					position: relative;
				    display: block;
				    padding: 10px 15px;
				    border-bottom: 1px solid #ddd;

				    span,i,h4{
				    	color: #d2232a;
				    	font-weight: bold;
				    }
				}
			}
		}
	}
}