.navbar__custom {
  width: 1170px;
  margin: auto;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
  @media (max-width: 48em) {
    position: absolute;
    width: 100%;
  }

  @media (min-width:768px) and (max-width:1024px) {
    .navbar-brand {
      transform: translateX(-50%);
      left: 52%;
      position: absolute;
    }
    .navbar-brand>img {
      height: 110px;
      width: 100px;
      padding: 7px 14px;
      display:block;
    }
    .custom__dropdown-container .custom__dropdown-panel {
      width: 1024px;
    }
    .custom__dropdown-container .custom__dropdown-panel .custom__dropdown-ul-left {
      width: 48%;
    }
    .custom__dropdown-container .custom__dropdown-panel .custom__dropdown-ul-right {
      width: 46%;
    }
    width: 100%;    
  }

  .navbar-toggle {
    border-color: #888;
  }
}

.navbar__custom.affix {
  background-color: #eaeae1;
  width: 100%;
  min-height: 50px;

  .hermes {
    display: block;
  }
  .container-fluid{
    display: flex;
    justify-content: center;
    height: 50px;
  }

  .navbar-brand > img {
    display: none;
  }
  .navbar-nav > li > a{
    padding: 15px 0 15px 0;
  }
  .navbar-nav {
    li{
      padding: 0;
      width: 200px;
      a{
        padding-bottom: 15px 0;
      }
    }
  }

  .custom__dropdown-container .custom__dropdown-panel {
    width: 1214px;
  }

  .custom__dropdown-container .custom__dropdown-panel .custom__dropdown-ul-left {
      width: 52%;
    }
    .custom__dropdown-container .custom__dropdown-panel .custom__dropdown-ul-right {
      width: 48%
    }
  .dropdown-menu {
    top: 50px;
  }
  @media (min-width:1025px) and (max-width:1281px) {
      .custom__dropdown-container .custom__dropdown-panel {
        width: 1000px;
      }
      .navbar-nav li {
        width: 170px;
      }

      .custom__dropdown-container .custom__dropdown-panel .custom__dropdown-ul-right {
        width: 46%;
      }
  }

  @media (min-width:768px) and (max-width:1024px) {
      .navbar-nav li {
        width: 155px;
      }
      .custom__dropdown-container .custom__dropdown-panel {
        width: 950px;
      }
  }
}

.navbar-brand {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
   -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}

/* DEMO example styles for logo image */
.navbar-brand {
  padding: 0px;
}
.navbar-brand>img {
  height: 155px;
  width: 140px;
  padding: 7px 14px;
  display:block;
}
@media (max-width: 48em) {
  .navbar-collapse {
    background-color: #eaeae1;
  }
}

.navbar__custom .navbar-nav>li {
    padding-bottom: 20px;
    width: 150px;
    text-align: center;
    @media (max-width: 48em) {
      padding: 0;
      text-align: left;
    }
}

.navbar__custom .navbar-nav>li>a {
  color: black;
  font-weight: bold;
}

.navbar__custom .navbar-nav>li>a>span{
  padding-bottom: 5px;
}

.navbar__custom .navbar-nav >li.active>a{
    color: black;

    span {
    // border-bottom: 4px solid #d2232a;
     color: #d2232a;
    }
}
.dropdown-menu {
  top: 100px;
}

.dropdown:hover .dropdown-menu {
    display: block;
}