.content__vote {
	.bg-img-criteria{
		background-image: url("../images/votes/voting-1920.jpg");
	}

	.content__title, .content__description {
		text-align: center;
	}

	.content__title {
		padding: 0 140px 20px 140px;
		font-family: "kepler-std-display";
		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.content__description {
		padding: 0 140px 0px 140px;
		a{
			color: #d2232a;
		}
		@media (max-width: 48em) {
			padding: 10px;
		}
		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}
		.vote_now_button {
			padding: 10px;
			width: 30%;
			background-color: #d2232a;
			color: #eaeae1;
			border-radius: 0;
			@media (max-width: 48em) { 
				width: 50%; 
			}
		}
		.vote_now_button:hover {
			color: #333333;
		}
	}

	.small_error_msg{
		color: red;
		font-weight: normal;
		font-style: italic;
	}
	.required_red{
		color: #d2232a;
	}

	.content__div {
		padding: 30px 0px 0 0;
		h1{
			font-family: "kepler-std-display";
		}

		.content__list{
			text-align: left;
			h4{
				font-weight: bold;
				color:#d2232a;
			}
			.criteria {
				list-style-type: none;
				padding: 0;
				li{
					padding: 7px 0 7px 0;
				}
			}
			ul>li{
					padding: 7px 0 7px 0;
			}
			p{
				font-weight: bold;
			}
		}

		.alert_field {
			display: none;
		}

		.content__nav {
			text-align: center;

			.nav-tabs {
				border-bottom: 3px solid #DCDCDC;

				ul {
				    list-style-type: none;
				    padding: 0;
				    overflow: hidden;
				    background-color: transparent;
				    max-width: 100%;
				    display: inline-block;


				    .active {
				    	a{
				    		color: #d2232a;
				    	}
				    }

				    li {
				    	float: left;

				    	// border-bottom: 3px solid #DCDCDC;
					}

					li a {
					    text-align: center;
					    padding: 14px 16px;
					    text-decoration: none;
					    color: black;
					    font-weight: bold;
					}
				}
			}
		}

		.content__container {
			padding: 30px 15px 30px 15px;
			display:flex;
			flex-direction: column;
			align-items: center;
  			justify-content: center;



			input {
				width: 300px;
			}

			.content__form-group{
				padding: 10px;

				label.error {
					display: none;
				}

				input, select, textarea {
					border: 1px solid #DCDCDC;
					color: black;
					padding: 5px;
					width: 100%;
					height:34px;
					width: 350px;
				}

				input:focus {
				    outline: none;
				    border:1px solid #d2232a;
				}
			}
		}
	}

	.content__footer {
		text-align: center;
		padding: 0px;

		.content__footer_panel{
			width: 100%;
			background-color: #222222;
			padding: 40px 50px;
			@media (max-width: 48em) { 
				padding: 40px 0;
			}

			h1,span {
				color: #d2232a;
			}

			p,h4,li{
				color: white;
			}

			h1,h4,p {
				padding: 30px;
				letter-spacing: 2px;

				@media (max-width: 48em) {
					padding: 0;
				}

			}
			.btn_back,
			.btn_submit {
				padding: 10px;
				width: 20%;
				background-color: #eaeae1;
				color: #333333;
				border-radius: 0;
				@media (max-width: 48em) {
					width: 100%;
					font-size: 14px;
				}
			}
			#continueButton {
				padding: 10px;
				width: 40%;
				background-color: #eaeae1;
				color: #333333;
				border-radius: 0;
				@media (max-width: 48em) {
					width: 100%;
					font-size: 14px;
				}
			}
			.btn_back,
			.btn_submit,
			#continueButton:hover {
				color: #d2232a;
			}
			
			.content__button{
				background-color: #d2232a; /* Green */
			    border: none;
			    color: white;
			    font-weight: bold;
			    padding: 10px 20px;
			    text-align: center;
			    text-decoration: none;
			    display: inline-block;
			    font-size: 22px;
			    cursor: pointer;
			    margin: 20px 15px 20px 15px;
			}

			button {
				background-color: #222222;
				border: none;
			}


			.disabled {
			   pointer-events: none;
			   cursor: default;
			}
		}

	}
}
