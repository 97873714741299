.custom__dropdown-container {
	height: 210px;
	width: 100%;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: none;
	.custom__dropdown-panel{
		width: 1138px;
		margin: auto;
		.custom__dropdown-ul-left{
			width: 41%;
			float: left;
			height: 210px;
			// margin-left: 20px;
			ul{
				list-style-type: none;
			}
			.custom__dropdown-list {
				height: 210px;
				padding: 0;
				ul{
					padding-left: 0;
					list-style-type: none;
					li{
						text-align: center;
						padding: 10px 0;
						display: flex;
						justify-content: center;
						align-items: center;
						a{
							color: white;
							text-decoration: none;
							font-size: 14px;
						}
					}
				}
			}
			.custom__dropdown-list:hover{
				background-color: rgb(234, 234, 225);
				border-top: 6px solid #d2232a;
				ul>li>a{
					color: #333333;
				}
				ul>li>a:hover{
					color: #d2232a;
				}
			}
		}
		.custom__dropdown-ul-right{
			width: 40%;
			float: right;
			height: 210px;
			// margin-left: 20px;
			.custom__dropdown-list {
				height: 210px;
				padding: 0;
				ul{
					padding-left: 0;
					list-style-type: none;
					li{
						padding: 10px 0;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						a{
							color: white;
							text-decoration: none;
							font-size: 14px;
						}
					}
				}
			}
			.custom__dropdown-list:hover{
				background-color: rgb(234, 234, 225);
				border-top: 6px solid #d2232a;
				ul>li>a{
					color: #333333;
				}
				ul>li>a:hover{
					color: #d2232a;
				}
			}
		}
	}
}