.content__header-home {
	height: 500px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
	@media (max-width: 48em) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 155px;
	}
	@media (min-width:768px) and (max-width:1024px) {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: initial;
		height: 400px;
		// display: block;
	}


	.content__header-title{
		top: 25%;
		position: absolute;
		padding: 0 200px;
		@media (max-width: 48em) {
			padding: 0;
			top: 0;
			position: relative;
		}
		@media (min-width:768px) and (max-width:1024px) {
			position: absolute;
			top: 25%;
		}
	
		h4{
			font-size: 70px;
			color: white;
			text-align: center;
			font-weight: bold;
			font-family: "kepler-std-display";
			@media (max-width: 48em) {
				padding: 0 50px;
				font-size: 20px;
			}
			@media (min-width:768px) and (max-width:1024px) {
				font-size: 70px;
			}
			
		}
		p{
			font-size: 30px;
			color: white;
			text-align: center;
			font-weight: bold;
			font-family: "kepler-std-display";
			@media (max-width: 48em) {
				padding: 0 50px;
				font-size: 10px;
			}

		}
	}
	a{
		position: absolute;
		top: 80%;
		background-color: #222222;
		padding: 5px;
		width: 320px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		@media (max-width: 48em) {
			top: 90%;
			width: 100%;
		}
		@media (min-width:768px) and (max-width:1024px) {
			width: 100%;
			top: 90%;
		}

		h4{
			color: #d2232a;
			text-align: center;
			@media (max-width: 48em) {
			font-size: 10px;
				}

		}
	}
	a:hover{
		width: 330px;
		padding: 10px 0; 
		text-decoration: none;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}