.content__about {
	.bg-img-events{
		background-image: url("../images/abouts/aboutevent-1920.jpg");
	}
	.bg-img-faqs{
		background-image: url("../images/abouts/faq-1920.jpg");
	}

	.content__title, .content__description {
		text-align: center;
	}

	.content__title {
		padding: 40px 140px 0px 140px;
		font-family: "kepler-std-display";

		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.content__description {
		padding: 20px 140px 40px 140px;
		h1.chinese-header{
			font-family: "source-han-serif-sc";
		}
		@media (max-width: 48em) {
			padding: 10px;
		}

		.media_show{
			display: block;
		}

		.media_hide{
			display: none;
		}
		iframe{
			width: 560px;
			height: 448px;
			@media (max-width: 48em) {
				width: 100%;
				height: 200px;
			}
		}

		video{
			margin: auto;
			@media (max-width: 48em) {
				width: 100%;
				height: 200px;
			}
		}

		h1{
			font-family: "kepler-std-display";
			text-decoration: none;
			a{
				color: #333333;
			}
		}
		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}

		.content__div {
			padding: 30px;
			@media (max-width: 48em) {
				padding: 0;
			}
			p{
				text-align: justify;
			}
			span {
				text-align: center;
			}
			h1.chinese-header{
				font-family: "source-han-serif-sc";
			}

		}

		.content__list-group {
			margin-bottom: 20px;
    		padding-left: 0;
    		text-align: left;
    		background-color: #f9f6f2;

			.content__list-group-item {
				position: relative;
			    display: block;
			    padding: 10px 15px;
			    border-bottom: 1px solid #ddd;

			    h4,span,i{
			    	color: #d2232a;
			    	font-weight: bold;
			    }
			}
		}
	}
}