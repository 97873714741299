.content__resource {
	.bg-img-news-release{
		background-image: url("../images/resources/newsrelease-1920.jpg");
	}

	.bg-img-photo-gallery{
		background-image: url("../images/resources/photogallery-1920.jpg");
	}

	.bg-img-pr-guidelines{
		background-image: url("../images/resources/pr-guidelines-1920.jpg");
	}

	.bg-img-downloads{
		background-image: url("../images/resources/downloads-1920.jpg");
	}

	.content__title, .content__description {

		text-align: center;
	}

	.text-padding{
		padding: 0 80px;
		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.content__title {
		padding: 40px 140px 0px 140px;
		font-family: "kepler-std-display";
		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.modal-body {
		display: flex;
		justify-content: center;
	}

	.imagetitle{
		text-align: center;
	}

	.modal-footer {
		text-align: center;
	}
	.modal-footer>.btn{
		width: 100%;
	}
	.content__description {
		.content__div {
			h4,h3,small{
				color: #d2232a;
			}
			img{
				max-width: 10%;
			}
			h4.chinese-header{
				font-family: "source-han-serif-sc";
			}
					a{
				color: #d2232a;
			}
			.download_attachment{
				color: white;
			}
		}
		.content__nav {
			.nav-tabs {
				ul {
				    list-style-type: none;
				    padding: 0;
				    overflow: hidden;
				    background-color: transparent;
				    max-width: 100%;
				    display: inline-block;
				    margin-top: 20px;
				    @media (max-width: 48em) {
				        display: flex;
					    flex-wrap: wrap;
					    justify-content: center;
					}

				    .active {a{color: black;}}

				    li {
				    	float: left;
					}

					li a {
		
					    text-align: center;
					    padding: 14px 16px;
					    text-decoration: none;
					    color: #d2232a;
					    font-weight: bold;
					}

					li:after {
						content: " \2022";
					}
					li.nobullet:after { content: none; }
				}
			}
		}
		.deco_line {
			padding: 10px;
			color: #d2232a;
			font-weight: bold;
			display: block;
			font-family: "kepler-std-display";
		}

		.grid {
		  background: #eaeae1;
		  margin: 0 auto;
		}

		/* clear fix */
		.grid:after {
		  content: '';
		  display: block;
		  clear: both;
		}

		/* ---- .grid-item ---- */

		.grid-sizer,
		.grid-item {
		  width: 25%;
		}
		.grid-item {
		  padding: 1px;
		}

		@media (max-width: 48em) {
			.grid-sizer,
			.grid-item {
			  width: 100%;
			}
		}

		.grid-item {
		  float: left;
		}

		.grid-item img {
			position: relative;
			display: block;
			max-width: 100%;
			opacity:0;
			-moz-transition: opacity 2s; /* Firefox 4 */
			-webkit-transition: opacity 2s; /* Safari and Chrome */
			-o-transition: opacity 2s;
			transition: opacity 2s;
			margin: auto;
		}


		.grid-item:hover{
			img{
				opacity: 0.7;
				filter: grayscale(100%);
			}

			h2{
				display: block;
				-webkit-transition: all 0.5s linear;
				-moz-transition: all 0.5s linear;
				-ms-transition: all 0.5s linear;
				-o-transition: all 0.5s linear;
				transition: all 0.5s linear;
			}
		}

		h2{
			position: absolute; 
			top: 150px; 
			left: 0; 
			width: 100%; 
			display: none;
			margin: 0;
			@media (max-width: 48em) {
				top: 300px;
			}
		}

		h2>span { 
			color: white;  
			letter-spacing: -1px;  
			// background: rgb(0, 0, 0); /* fallback color */
			// background: rgba(0, 0, 0, 0.7);
			// padding: 10px; 
			font-size: 13px;
			font-weight: normal;
		}
	}

	.content__button{
				background-color: black; /* Green */
			    border: none;
			    color: white;
			    padding: 14px 35px;
			    text-align: center;
			    text-decoration: none;
			    display: inline-block;
			    font-size: 16px;
			    cursor: pointer;
			    margin: 20px 15px 20px 15px;
	}

}