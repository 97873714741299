.content__home {
	.bg-img-post-event {
		background-image: url("../images/homes/postevent/main-banner-1920x1080.jpg");
	}

	.bg-img-home-banner {
		background-image: url("../images/homes/postevent/content-banner-1920.jpg");
	}
	.content__title, .content__description, .content__div {
		text-align: center;
	}
	.content__home-ol {
		display: flex;
		justify-content: center;
		ol {
			list-style-type: decimal!important;
			li{
				text-align: left;
				padding-left: 10px;
				width: 200px;
			}
		}
	}
	.content__title {
		padding: 40px 140px 0px 140px;
		font-family: "kepler-std-display";
		h1.chinese-header{
			font-family: "source-han-serif-sc";
		}
		@media (max-width: 48em) {
			padding: 0;
			margin-top: 50px;
		}
	}

	.content__description {
		padding: 0px 140px;
		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}
		@media (max-width: 48em) {
			padding: 0;
		}
	}

	.content__div {
		padding: 10px;
		@media (max-width: 48em) {
			padding: 10px;
		}

		.laurel_container {
			display:flex;
			align-items: center;
  			justify-content: center;
				.laurel_logo{
					width: 250px;
					height: 250px;

				}
			@media (max-width: 48em) {
				display: block;
			}
		}

		ul{
			list-style-type: none;
		}

		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}

		h4 {
			font-weight: bold;
		}

		.content__button_div {
			padding: 30px;
			
			.content__button{
				background-color: black; /* Green */
			    border: none;
			    color: #eaeae1;
			    padding: 15px 30px;
			    text-align: center;
			    text-decoration: none;
			    display: inline-block;
			    font-size: 16px;
			    cursor: pointer;
			    margin: 20px 30px 20px 30px;
			}	
		}	
	}


	.content__footer {
		text-align: center;
			
		.content__footer_button{
			background-color: black; /* Green */
		    border: none;
		    color: #eaeae1;
		    padding: 14px 50px;
		    text-align: center;
		    text-decoration: none;
		    display: inline-block;
		    font-size: 16px;
		    margin: 10px 30px 30px 30px;
		    cursor: pointer;
		}

		.content__footer_banner{
			width: 100%;
		}

		.content__footer_panel{
			width: 100%;
			background-color: #222222;
			padding: 40px 50px;
			@media (max-width: 48em) {
				padding: 40px 0px;
			}

			ol {
				list-style-type: none;
			}
			
			span {
				font-weight: bold;
			}
			
			h1,span {
				color: #d2232a;
				font-family: "kepler-std-display";
			}

			p,h4,li{
				color: #eaeae1;
			}

			h1,h4,p {
				padding: 10px;
			}
			h1.chinese-header{
              font-family: "source-han-serif-sc";
            }
		}

	}
}

