.content__contact {
	.bg-img-contacts{
		background-image: url("../images/contacts/contact-1920.jpg");
	}

	.content__title, .content__description {
		text-align: center;
	}

	.content__title {
		padding: 40px 140px 0px 140px;
		font-family: "kepler-std-display";
		@media (max-width: 48em) {
			padding: 10px;
		}
	}

	.content__description {
		padding: 20px 140px 40px 140px;
		a{
			color: #d2232a;
		}
		@media (max-width: 48em) {
			padding: 10px;
		}
		@media (min-width:768px) and (max-width:1024px) {
			padding: 10px;
		}
		span {
			color: #d2232a;
			font-weight: bold;
			font-family: "kepler-std-display";
		}

		h4{
			font-weight: bold;
		}

		.content__div {
			padding: 30px;
			@media (max-width: 48em) {
			padding: 0;
			}

			.map {
				iframe {
					width: 500px;
					height: 350px;
					border: 0;
				}

				@media (max-width: 48em) {
					iframe {
						width: 100%;
						height: 350px;
						border: 0;
					}
				}
			}
		}

		.media__head {
			padding-top: 30px;
		}
	}
}